<template>
  <div style="width: 100%" id="listener_benestare">
    <!-- Allegati Modal-->
    <v-dialog
      persistent
      content-class="artDialog"
      v-model="benestareallegatidialog"
      width="unset"
    >
      <GestioneAllegato
        ref="componenteAllegati"
        controller_allegati="Global"
        controller_caratteristiche_tabella="Manutenzione"
        :id_riferimento="testaCommune.id_benestare"
        tipo_allegato="BENESTARE"
        @snackbarOpen="snackbarOpen"
      />
    </v-dialog>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">Benestare Dettaglio</v-toolbar-title>
      <v-col cols="2"></v-col>
      <v-col cols="6">
        <v-btn light small style="margin-right: 5px">
          <v-icon color="primary">mdi-folder-multiple-image</v-icon>
        </v-btn>
        <v-btn light small style="margin-right: 5px">
          <v-icon color="primary">mdi-note-outline</v-icon>
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              light
              @click="benestareallegatidialog = true"
              small
            >
              <v-icon color="primary">mdi-paperclip</v-icon>
            </v-btn>
          </template>
          <span>Salva Allegati</span>
        </v-tooltip>
        <Button
          classe_bottone="ml-1"
          colore_icona="success"
          idForm="QUALITA"
          idOggetto="SALVA_BENESTARE"
          mdi_icona="mdi-floppy"
          testo_tooltip="Salva"
          v-on:buttonClick="salvabenestare"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="clickChiudi" small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Torna al Benestare</span>
      </v-tooltip>
    </v-toolbar>
    <div>
      <TestaCommuneNuova
        :key="testaCommune.id_benestare"
        v-on:update="getVal($event)"
        v-on:fmea_rev="getfmearev($event)"
        v-on:datagenerale="getdatagen($event)"
        v-on:articologenerale="getarticologen($event)"
        v-on:desgenerale="getdesgenerale($event)"
        v-on:codartgen="getcodartgen($event)"
        v-on:indicegenerale="getindicegenerale($event)"
        v-on:dumgen="getdumgen($event)"
        v-on:criticagen="getcriticagen($event)"
        v-on:idarticolo="getidarticolo($event)"
        v-on:fasiattgen="getfasiattgen($event)"
        v-on:fasiattsucc="getdesfasesuccessive($event)"
        v-on:des_fase_attuale="getdesfaseattuale($event)"
        v-on:fasesucc="getfasesucc($event)"
        v-on:macchinagen="getmacchinagen($event)"
        v-on:approvatogen="getapprovatogen($event)"
        v-on:sendragione="sendragione($event)"
        v-on:sendidFronitore="sendidFronitore($event)"
        :testaCommune="testaCommune"
      />
      <v-row style="margin-left: 12px; margin-right: 12px; margin-top: 5px">
        <v-data-table
          style="width: 100%"
          :headers="headers_benestare"
          :items="items_benestare"
          :key="contatore"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar color="indigo" dense rounded-sm>
              <v-toolbar-title color="indigo" class="white--text"
                >Elementi</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <Button
                classe_bottone="ml-1"
                colore_icona="primary"
                idForm="QUALITA"
                idOggetto="CREA_BENESTARE_ELEMENT"
                mdi_icona="mdi-plus"
                testo_tooltip="Aggiungi elemento"
                v-on:buttonClick="addRecord()"
              />
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.detail`]="{ item }">
            <Button
              colore_icona="primary"
              idForm="QUALITA"
              idOggetto="MODIFICA_BENESTARE_ELEMENT"
              mdi_icona="mdi-pencil"
              testo_tooltip="Dettaglio elemento"
              v-on:buttonClick="dettaglio_benestare(item)"
            />
          </template>
          <!-- Spazio per la miniatura della foto da webcam -->
          <template v-slot:[`item.miniatura`]="{ item }">
            <div
              :id="item.id_benestare_dettaglio"
              style="
                background-repeat: no-repeat;
                background-size: 100% 100%;
                border: 1px solid black;
                height: 100px;
                margin-top: 5px;
                width: 100px;
              "
            ></div>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <Button
              colore_icona="error"
              idForm="QUALITA"
              idOggetto="ELIMINA_BENESTARE_ELEMENT"
              mdi_icona="mdi-window-close"
              testo_tooltip="Cancellare"
              v-on:buttonClick="deleteRecord(item)"
            />
          </template>
        </v-data-table>
      </v-row>
      <v-dialog v-model="dialog_gestione_record" width="800">
        <div
          style="
            background-color: rgba(33, 33, 33, 0.46);
            bottom: 0;
            height: 100%;
            z-index: 2;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            width: 100%;
          "
          v-if="controllo_qualita_visible"
        >
          <div
            style="
              height: 90%;
              left: 5%;
              position: absolute;
              top: 5%;
              transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
              width: 90%;
            "
          >
            <Controllo
              @click_indietro="controllo_qualita_visible = false"
              :destinazione="'listener_benestare'"
              :id_articolo="testaCommune.id_articolo"
              :riferimento="
                dettaglio_object.id_benestare_dettaglio != undefined
                  ? dettaglio_object.id_benestare_dettaglio
                  : -1
              "
            />
          </div>
        </div>
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text" v-if="modifica == false"
              >Aggiungi elemento</v-toolbar-title
            >
            <v-toolbar-title class="white--text" v-else
              >Modifica elemento</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="opencamera" v-bind="attrs" v-on="on" small color="#f3f3f3"
                  ><v-icon color="primary">mdi-paperclip</v-icon></v-btn
                >
              </template>
              <span>Allega file </span>
            </v-tooltip>
            <Button
              classe_bottone="ml-1"
              colore_icona="success"
              idForm="QUALITA"
              idOggetto="SALVA_BENESTARE_ELEMENT"
              mdi_icona="mdi-floppy"
              testo_tooltip="Salva"
              v-on:buttonClick="salvacaratteristicha"
            />
            <v-spacer></v-spacer>
            <v-btn
              light
              small
              @click="
                (dialog_gestione_record = false),
                  (tolleranza_selezionata_da = null),
                  (tolleranza_selezionata_a = null)
              "
            >
              <v-icon color="error"> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col cols="2" class="py-0">
                    <TextField
                      idForm="BENESTARE"
                      idOggetto="BENESTARE_RIGA"
                      label_input="Riga"
                      palceholder_input="Riga"
                      :vmodel="riga"
                      v-on:changeText="(val) => (this.riga = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col cols="4" class="mt-1">
                    <v-autocomplete
                      dense
                      v-model="dettaglio_object.id_caratteristica"
                      :items="caratteristiche"
                      item-text="des"
                      item-value="id_tabella"
                      label="Caratteristiche"
                      placeholder="Caratteristiche"
                    >
                      <template #label>
                        <span class="">Caratteristiche</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="CARATTERISTICHE"
                            nome="Caratteristiche"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="mt-1">
                    <v-autocomplete
                      dense
                      v-model="dettaglio_object.id_modo_guasto"
                      :items="modi_di_guasto"
                      item-text="des"
                      item-value="id_tabella"
                      label="Guasto"
                      placeholder="Guasto"
                    >
                      <template #label>
                        <span class="">Guasto</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="MODO_DI_GUASTO"
                            nome="Modo di Guasto"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <TextField
                      :readonly="true"
                      idForm="BENESTARE"
                      idOggetto="BENESTARE_TOLLERANZA"
                      label_input="Tolleranza"
                      palceholder_input="Tolleranza"
                      :vmodel="tolleranza"
                      v-on:changeText="(val) => (this.tolleranza = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      dense
                      v-model="dettaglio_object.id_strumento"
                      :items="strumenti"
                      item-text="des"
                      item-value="id_tabella"
                      label="Fam. strumento"
                      placeholder="Fam. strumento"
                    >
                      <template #label>
                        <span class="">Fam. Strumenti</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="STRUMENTI"
                            nome="Fam. Strumenti"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      dense
                      v-model="dettaglio_object.id_criticita"
                      :items="criticita"
                      item-text="des"
                      item-value="id_tabella"
                      placeholder="Criticità"
                      label="Criticità"
                    >
                      <template #label>
                        <span class="">Criticità</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="CRITICITA"
                            nome="Criticità"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <span style="font-size: 16px">Tolleranza:</span>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <TextField
                      idForm="BENESTARE"
                      idOggetto="BENESTARE_TOLLERANZA_DA"
                      label_input="Primo valore"
                      palceholder_input="Primo valore"
                      :vmodel="tolleranza_selezionata_da"
                      v-on:changeText="(val) => (this.tolleranza_selezionata_da = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <TextField
                      idForm="BENESTARE"
                      idOggetto="BENESTARE_TOLLERANZA_A"
                      label_input="Secondo valore"
                      palceholder_input="Secondo valore"
                      :rules_input="[rules.min]"
                      :vmodel="tolleranza_selezionata_a"
                      v-on:changeText="(val) => (this.tolleranza_selezionata_a = val)"
                      :min_input="0"
                      ref="tolleranza_selezionata_a"
                      type_input="number"
                    />
                  </v-col>
                  <v-col cols="7">
                    <v-textarea
                      v-model="note"
                      outlined
                      placeholder="Note"
                      label="Note"
                      :rows="2"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import GestioneAllegato from "../../TOOLS/GestioneAllegato.vue";
import TestaCommuneNuova from "../TestaCommuneNuova";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";
export default {
  components: {
    TestaCommuneNuova,
    GestioneAllegato,
    Controllo,
    Button,
    TextField,
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  data() {
    return {
      rules: {
        // RULE in order to keep track of A VALUE of min and max value
        min: (value) => {
          if (this.tolleranza_selezionata_da != null) {
            if (this.tolleranza_selezionata_da != null && value != null) {
              return parseInt(value) > parseInt(this.tolleranza_selezionata_da);
            } else return false;
          } else return true;
        },
      },
      controllo_qualita_visible: false,
      dettaglio_object: {
        cod_strumento: null,
        date_ins: null,
        date_mod: null,
        des_strumento: null,
        des_caratteristica: null,
        des_guasto: null,
        des_criticita: null,
        id_benestare: -1,
        id_benestare_dettaglio: -1,
        id_caratteristica: null,
        id_criticita: null,
        id_modo_guasto: null,
        id_strumento: null,
        id_utente: null,
        note: null,
        primo_controllo_valore: null,
        riga: null,
        secondo_controllo_valore: null,
        tolleranza: null,
        miniatura: "",
        validita: 1,
      },
      // DATA Use to communicate between the TestCommune
      main_info_object: {
        id_benestare: -1,
        num_benestare: null,
        descrizione: null,
        stato: null,
        des_stato: null,
        id_articolo: null,
        cod_articolo: null,
        des_articolo: null,
        cod_articolo_esterno: null,
        indice_modifica: null,
        dum: null,
        id_macchina: null,
        id_ciclo_attuale: null,
        id_ciclo_successivo: null,
        id_fase_attuale: null,
        id_fase_successivo: null,
        id_criticita: null,
        id_firmatario: null,
        id_fmea: null,
        id_fmea_fasi: null,
        num_revisione: null,
        data_benestare: null,
        note: null,
        validita: null,
        date_ins: null,
        date_mod: null,
        id_utente: null,
        nominativo_firmatario: null,
        des_fase_attuale: null,
        des_fase_successivo: null,
      },
      // SelectedElement
      sel_elem: {},
      // Deleted Ciclo autocontrollo
      deletedbenes: [],
      // Lists for storing V-autocomplete Lists
      caratteristiche: [],
      // Strumenti lista
      strumenti: [],
      // Crtitcita Lista
      criticita: [],
      // Guasto Lista
      modi_di_guasto: [],
      testaCommune: {},
      headers_benestare: [
        {
          text: "",
          value: "detail",
          sortable: false,
        },
        {
          text: "Riga",
          value: "riga",
          align: "center",
          sortable: false,
        },
        {
          text: "Tipo carat.",
          value: "des_caratteristica",
          align: "center",
          sortable: false,
        },
        {
          text: "Guasto",
          value: "des_guasto",
          align: "center",
          sortable: false,
        },
        {
          text: "Strumento",
          value: "des_strumento",
          align: "center",
          sortable: false,
        },
        {
          text: "Criticità",
          value: "des_criticita",
          align: "center",
          sortable: false,
        },
        {
          text: "Primo valore",
          value: "primo_controllo_valore",
          align: "center",
          sortable: false,
        },
        {
          text: "Secondo valore",
          value: "secondo_controllo_valore",
          align: "center",
          sortable: false,
        },
        {
          text: "Tolleranza",
          value: "tolleranza",
          align: "center",
          sortable: false,
        },
        {
          text: "Note",
          value: "note",
          align: "center",
          sortable: false,
        },
        {
          text: "Miniatura",
          value: "miniatura",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "delete",
          sortable: false,
        },
      ],
      items_benestare: [],
      dialog_gestione_record: false,
      dialog_elimina_record: false,
      benestareallegatidialog: false,
      modifica: false,
      id_caratteristica: null,
      tolleranza_selezionata: null,
      id_strumento: null,
      tolleranza_selezionata_da: null,
      tolleranza_selezionata_a: null,
      indice_record: null,
      note: null,
      tolleranza: null,
      id_criticita: null,
      caratteristica_des: null,
      strumento_des: null,
      criticita_des: null,
      contatore: 0,
      riga: null,
      imageBlob: "",
      id_benestare: "",
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      overlay: false,
    };
  },
  watch: {
    tolleranza_selezionata_da: {
      immediate: true,
      async handler() {
        if (this.$refs.form != undefined) {
          await this.$nextTick();
          this.$refs.form.validate();
        }
      },
    },
    // here we are calculating thr tolleranza
    tolleranza_selezionata_a: function () {
      const I = this.tolleranza_selezionata_a;
      this.tolleranza =
        parseFloat(this.tolleranza_selezionata_a) -
        parseFloat(this.tolleranza_selezionata_da);
      this[I] = !this[I];
    },
  },
  methods: {
    opencamera() {
      this.controllo_qualita_visible = true;
    },
    // add allegati to each single element
    aggiungiImg(messaggio) {
      this.dialog_gestione_record = false;
      /* Recupero l'immagine nel messaggio
       * che è un base64 non splittato. */
      this.imageBlob = messaggio.detail.img;
      let photo = messaggio.detail.img;
      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();
      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width > img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.detail.id).style.width = width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height = height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.detail.id).style.width = width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height = height_nuova + "px";
        } else {
          document.getElementById(messaggio.detail.id).style.width = img.width + "px";
          document.getElementById(messaggio.detail.id).style.height = img.height + "px";
        }
      };
      // Trasformo l'immagine del messaggio nello sfondo del mio div
      img.src = photo;
      document.getElementById(messaggio.detail.id).style.backgroundImage =
        "url('" + img.src + "')";
    },
    //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    ///////////////////////////// Following are Functions Getting All Values from the Shared Component
    getVal(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.numero_info = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.numero_info = ev;
      }
    },
    getdesfaseattuale(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.des_fase_attuale = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.des_fase_attuale = ev;
      }
    },
    getdesfasesuccessive(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.des_fase_successivo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.des_fase_successivo = ev;
      }
    },
    getfmearev(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.fmea_rev = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.fmea_rev = ev;
      }
    },
    getdatagen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.data_benestare = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.data_benestare = ev;
      }
    },
    getarticologen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.cod_articolo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.cod_articolo = ev;
      }
    },
    getdesgenerale(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.descrizione = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.descrizione = ev;
      }
    },
    getcodartgen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.cod_articolo_esterno = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.cod_articolo_esterno = ev;
      }
    },
    getidarticolo(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_articolo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_articolo = ev;
      }
    },
    getindicegenerale(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.indice_modifica = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.indice_modifica = ev;
      }
    },
    getdumgen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.dum = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.dum = ev;
      }
    },
    getcriticagen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_criticita = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_criticita = ev;
      }
    },
    getfasiattgen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_fase_attuale = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_fase_attuale = ev;
      }
    },
    getfasesucc(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_fase_successivo = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_fase_successivo = ev;
      }
    },
    getmacchinagen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_macchina = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_macchina = ev;
      }
    },
    getapprovatogen(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.nominativo_firmatario = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.nominativo_firmatario = ev;
      }
    },
    sendragione(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.ragione_sociale = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.ragione_sociale = ev;
      }
    },
    sendidFronitore(ev) {
      if (this.$route.params.foo == -1) {
        this.main_info_object.id_fornitore = ev;
      }
      if (this.$route.params.foo > 0) {
        this.testaCommune.id_fornitore = ev;
      }
    },
    ////////////////////////////////////Getting Values END Here/////////
    async salvabenestare() {
      if (this.$refs.componenteAllegati != undefined) {
        console.log(
          "this.$refs.componenteAllegati.allegato_sel.des_allegato",
          this.$refs.componenteAllegati.allegato_sel.des_allegato
        );
        if (!this.$refs.componenteAllegati.allegato_sel.des_allegato) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "È necessario des di allegato.";
          this.snackbar_text_color = "white";
          return;
        }
      }
      this.overlay = true;
      this.main_info_object.qm_benestare_dettaglio = this.items_benestare;
      if (this.$route.params.foo == -1) {
        this.main_info_object.validita = 1;
        this.main_info_object.qm_benestare_dettaglio = this.items_benestare;
        let setBenestare = {
          controller: "qualita",
          method: "PUT",
          richiesta: {
            action: "setbenestare",
            token: localStorage.user_token,
            qm_benestare: [this.main_info_object],
            id_benestare: -1,
          },
        };
        await this.$store.dispatch("api", setBenestare).then(async (res) => {
          this.snackbar = true;
          this.overlay = false;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          if (res.status == 200 && this.$refs.componenteAllegati != undefined) {
            this.$refs.componenteAllegati.id_riferimento_creazione_genitore = res.data;
            this.$refs.componenteAllegati.salvaTuttiAllegati();
          }
          this.$router.push({
            name: "Qualita",
            params: {
              comp: 3,
              id: this.main_info_object.id_articolo,
            },
          });
        });
      }
      // This is logic for Updating A ciclo auto controllo
      if (this.$route.params.foo > 0) {
        if (this.deletedbenes && this.deletedbenes.length > 0) {
          this.deletedbenes.map((it) => {
            this.items_benestare.push(it);
          });
        }
        this.testaCommune.id_articolo = this.id_articolo
        this.testaCommune.qm_benestare_dettaglio = this.items_benestare;
        console.log("this.items_benestare :>> ", this.items_benestare);
        let setBenestare = {
          controller: "qualita",
          method: "PUT",
          richiesta: {
            action: "setbenestare",
            token: localStorage.user_token,
            qm_benestare: [this.testaCommune],
            id_benestare: this.testaCommune.id_benestare,
          },
        };
        await this.$store.dispatch("api", setBenestare).then((res) => {
          if (res.status == 200 && this.$refs.componenteAllegati != undefined) {
            this.$refs.componenteAllegati.salvaTuttiAllegati();
          }
          this.overlay = false;
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          this.$router.push({
            name: "Qualita",
            params: {
              comp: 3,
              id: this.testaCommune.id_articolo,
            },
          });
        });
      }
    },
    salvacaratteristicha() {
      // console.log('this.dettaglo_object.id_ciclo_auto_controllo :>> ', this.dettaglio_object.id_ciclo_autocontrollo);
      // This is the control to check empty num riga
      if (this.riga === null || this.riga === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "È necessario specificare un numero di riga.";
        this.snackbar_text_color = "white";
        return;
      }
      // this is logic to create new caratteristica
      if (this.dettaglio_object.id_benestare > -1) {
        for (let index = 0; index < this.items_benestare.length; index++) {
          const element = this.items_benestare[index];
          if (element.riga == this.riga) {
            if (this.sel_elem != element) {
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar_text =
                "Non è possibile salvare due righe con lo stesso codice";
              return;
            }
          }
        }
        this.dettaglio_object.riga =
          this.riga == null ? this.dettaglio_object.riga : this.riga;
        this.dettaglio_object.tolleranza = this.tolleranza;
        this.dettaglio_object.primo_controllo_valore = this.tolleranza_selezionata_da;
        this.dettaglio_object.secondo_controllo_valore = this.tolleranza_selezionata_a;
        this.dettaglio_object.note = this.note;
        this.dettaglio_object.miniatura = this.imageBlob;
        console.log(" :>> ", this.imageBlob);
        // Here i am getting the values of ids in order to populate the table it will end with comment ending with number*
        if (this.dettaglio_object.id_caratteristica > -1) {
          this.caratteristiche.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_caratteristica) {
              this.dettaglio_object.des_caratteristica = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_fmea_modo_guasto > -1) {
          this.modi_di_guasto.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_modo_guasto) {
              this.dettaglio_object.des_guasto = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_strumento > -1) {
          this.strumenti.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_strumento) {
              this.dettaglio_object.des_strumento = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_criticita > -1) {
          this.criticita.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_criticita) {
              this.dettaglio_object.des_criticita = it.des;
            }
          });
        }
        //////////////////////**************************************/
        let i = this.items_benestare.findIndex((it) => it == this.dettaglio_object);
        this.items_benestare.splice(i, 1, this.dettaglio_object);
        this.items_benestare.sort((a, b) => parseFloat(a.riga) - parseFloat(b.riga));
        document
          .getElementById("listener_benestare")
          .addEventListener("nuova_img", this.aggiungiImg);
        this.dialog_gestione_record = false;
      } else {
        for (let index = 0; index < this.items_benestare.length; index++) {
          const element = this.items_benestare[index];
          if (element.riga == this.riga) {
            if (this.dettaglio_object != element) {
              this.snackbar = true;
              this.snackbar_color = "error";
              this.snackbar_icon = "mdi-window-close";
              this.snackbar_text =
                "Non è possibile salvare due righe con lo stesso codice";
              return;
            }
          }
        }
        ////////////Here i am Creating a new Caratteristicha
        this.dettaglio_object.validita = 1;
        this.dettaglio_object.riga = this.riga;
        this.dettaglio_object.tolleranza = this.tolleranza;
        this.dettaglio_object.primo_controllo_valore = this.tolleranza_selezionata_da;
        this.dettaglio_object.secondo_controllo_valore = this.tolleranza_selezionata_a;
        this.dettaglio_object.note = this.note;
        this.dettaglio_object.miniatura = this.imageBlob;
        if (this.dettaglio_object.id_caratteristica > -1) {
          this.caratteristiche.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_caratteristica) {
              this.dettaglio_object.des_caratteristica = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_modo_guasto > -1) {
          this.modi_di_guasto.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_modo_guasto) {
              this.dettaglio_object.des_guasto = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_strumento > -1) {
          this.strumenti.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_strumento) {
              this.dettaglio_object.des_strumento = it.des;
            }
          });
        }
        if (this.dettaglio_object.id_criticita > -1) {
          this.criticita.map((it) => {
            if (it.id_tabella == this.dettaglio_object.id_criticita) {
              this.dettaglio_object.des_criticita = it.des;
            }
          });
        }
        this.dialog_gestione_record = false;
        // this.dettaglio_object.riga = this.main_info_object.id_fase_attuale
        const i = this.items_benestare.findIndex((it) => it == this.dettaglio_object);
        if (i > -1) {
          this.items_benestare.splice(i, 1, this.dettaglio_object);
          this.items_benestare.sort((a, b) => parseFloat(a.riga) - parseFloat(b.riga));
        }
        if (i == -1) {
          this.items_benestare.push(this.dettaglio_object);
          document
            .getElementById("listener_benestare")
            .addEventListener("nuova_img", this.aggiungiImg);
          this.items_benestare.sort((a, b) => parseFloat(a.riga) - parseFloat(b.riga));
        }
      }
      this.dialog_gestione_record = false;
    },
    getTabelleAutocontrollo() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo:
            "'CARATTERISTICHE','MODO_DI_GUASTO','CRITICITA','CAUSA','STRUMENTI','UNITA_MISURA_CONTROLLO'",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.caratteristiche = res.data.CARATTERISTICHE;
        this.modi_di_guasto = res.data.MODO_DI_GUASTO;
        this.strumenti = res.data.STRUMENTI;
        this.criticita = res.data.CRITICITA;
      });
    },
    dettaglio_benestare(item) {
      this.sel_elem = item;
      this.modifica = true;
      this.dettaglio_object = item;
      this.id_benestare = item.id_benestare;
      this.dialog_gestione_record = true;
      this.id_caratteristica = item.id_caratteristica;
      this.id_strumento = item.id_strumento;
      this.id_criticita = item.id_criticita;
      this.tolleranza = item.tolleranza;
      this.tolleranza_selezionata_da = item.primo_controllo_valore;
      this.tolleranza_selezionata_a = item.secondo_controllo_valore;
      this.note = item.note;
      this.riga = item.riga;
    },
    clickChiudi() {
      if (this.$route.params.foo > 0) {
        this.$router.push({
          name: "Qualita",
          params: {
            comp: 3,
            id: this.testaCommune.id_articolo,
          },
        });
      } else {
        this.$router.push({
          name: "Qualita",
          params: { comp: 3 },
        });
      }
    },
    checkRecord() {
      if (
        this.criticita_selezionata == null ||
        this.criticita_selezionata == "" ||
        this.tolleranza_selezionata == null ||
        this.tolleranza_selezionata == "" ||
        this.tolleranza_selezionata_da == null ||
        this.tolleranza_selezionata_da == "" ||
        this.tolleranza_selezionata_a == null ||
        this.tolleranza_selezionata_a == ""
      ) {
        this.$store.state.snackbar.stato = true;
        this.$store.state.snackbar.messaggio =
          "Compilare i campi Criticità e Tolleranza prima di continuare!";
        this.$store.state.snackbar.colore = "#E53935";
        this.$store.commit("close_snackbar");
        return false;
      } else {
        return true;
      }
    },
    addRecord() {
      this.tolleranza_selezionata_da = null;
      this.tolleranza_selezionata_a = null;
      this.riga = null;
      this.modifica = false;
      this.note = null;
      this.tolleranza = null;
      this.dialog_gestione_record = true;
      this.dettaglio_object = {
        cod_strumento: null,
        date_ins: null,
        date_mod: null,
        des_strumento: null,
        des_caratteristica: null,
        des_guasto: null,
        des_criticita: null,
        id_benestare: -1,
        id_benestare_dettaglio: -1,
        id_caratteristica: null,
        id_criticita: null,
        id_modo_guasto: null,
        id_strumento: null,
        id_utente: null,
        note: null,
        primo_controllo_valore: null,
        riga: null,
        secondo_controllo_valore: null,
        tolleranza: null,
        validita: 1,
      };
    },
    associaSelect() {
      this.caratteristiche.forEach((e) => {
        if (this.caratteristica_selezionata == e.id) {
          this.caratteristica_des = e.des;
        }
      });
      this.strumenti.forEach((e) => {
        if (this.strumento_selezionato == e.id) {
          this.strumento_des = e.des;
        }
      });
      this.criticita.forEach((e) => {
        if (this.criticita_selezionata == e.id) {
          this.criticita_des = e.des;
        }
      });
    },
    editRecord(item) {
      this.sel_elem = item;
      this.riga = item.riga;
      this.modifica = true;
      this.dettaglio_object = item;
      this.tolleranza = this.dettaglio_object.tolleranza;
      this.tolleranza_selezionata_da = this.dettaglio_object.primo_controllo_valore;
      this.tolleranza_selezionata_a = this.dettaglio_object.secondo_controllo_valore;
      this.note = item.note;
      this.modifica = true;
      this.dialog_gestione_record = true;
    },
    deleteRecord(item) {
      if (item.id_benestare > -1) {
        item.validita = 0;
        this.deletedbenes.push(item);
        let i = this.items_benestare.findIndex((it) => it == item);
        this.items_benestare.splice(i, 1);
      } else {
        let i = this.items_benestare.findIndex((it) => it == item);
        this.items_benestare.splice(i, 1);
      }
    },
  },
  mounted() {
    document
      .getElementById("listener_benestare")
      .addEventListener("nuova_img", this.aggiungiImg);
    let doc = document.getElementById("listener_benestare");
    console.log("doc :>> ", doc);
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.benestareallegatidialog = false;
        this.dialog_gestione_record = false;
        this.dialog_elimina_record = false;
      }
    });
    this.overlay = false;
    this.getTabelleAutocontrollo();
    if (this.$route.params.foo > 0) {
      this.overlay = true;
      let getPianoControllo = {
        controller: "qualita",
        method: "POST",
        richiesta: {
          action: "getbenestare",
          token: localStorage.user_token,
          id_benestare: this.$route.params.foo,
        },
      };
      this.$store.dispatch("api", getPianoControllo).then((res) => {
        this.overlay = false;
        this.items_benestare = res.data.qm_benestare[0].qm_benestare_dettaglio;
        this.id_articolo = res.data.qm_benestare[0].id_articolo
        this.testaCommune = res.data.qm_benestare[0];
      });
    }
  },
};
</script>
